var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value != null ? _c('div', {
    staticClass: "text-stat"
  }, [_vm.tooltip ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({
          staticClass: "darkGrey--text"
        }, on), [_vm._v(_vm._s(_vm.value))])];
      }
    }], null, false, 118644528)
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.tooltip)
    }
  })]) : _c('span', {
    staticClass: "darkGrey--text"
  }, [_vm._v(_vm._s(_vm.value))])], 1) : _c('empty');

}
var staticRenderFns = []

export { render, staticRenderFns }