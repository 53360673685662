<template>
  <div v-if="value != null" class="text-stat">
    <v-tooltip v-if="tooltip" bottom>
      <template #activator="{ on }">
        <span class="darkGrey--text" v-on="on">{{ value }}</span>
      </template>
      <div v-html="tooltip"></div>
    </v-tooltip>
    <span class="darkGrey--text" v-else>{{ value }}</span>
  </div>
  <empty v-else />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-stat {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.18px;
  line-height: 1;
}
</style>
