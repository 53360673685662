<template>
  <v-card v-if="stats?.length" :outlined="$vuetify.theme.dark">
    <v-row>
      <template v-for="(stat, index) in stats">
        <v-col class="pa-3" cols="6" md="auto" sm="6" :key="stat?.title">
          <div class="stat__title ma-4" gap="8px">
            <svg-icon :name="stat?.titleIcon" />
            <span class="darkGrey--text">{{ stat?.title }}</span>
          </div>
          <div class="stat_value ml-3">
            <component
              :is="getStatComponent(stat?.type)"
              :tooltip="stat?.tooltip"
              :value="stat?.value"
              v-bind="stat?.props"
            />
          </div>
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.smAndDown && index < stats.length - 1"
          :key="index"
          class="pa-2"
          cols="auto"
        >
          <v-divider class="stats-devider" inset vertical />
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>

<script>
import TextStat from "@/view/components/Common/StatsPanel/types/TextStat.vue";
import DateStat from "@/view/components/Common/StatsPanel/types/DateStat.vue";
import ClockStat from "@/view/components/Common/StatsPanel/types/ClockStat.vue";

export default {
  props: {
    stats: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getStatComponent(type) {
      if (type === "clock") {
        return ClockStat;
      }

      if (type === "date") {
        return DateStat;
      }

      return TextStat;
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-panel {
  display: flex;
  gap: 80px;
  padding: 24px;
}

.stats-devider {
  height: 85px;
}
.stat {
  min-width: 120px;
  position: relative;

  & + & {
    &:before {
      background: lightgrey;
      content: "";
      height: calc(100% + 20px);
      left: -40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }
  }
}
</style>
