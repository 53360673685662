<template>
  <div v-if="value" class="date-stat darkGrey--text">
    <vue-hoverable-date
      :date="value | unix"
      :timezone="timezone"
    ></vue-hoverable-date>
  </div>
  <empty v-else />
</template>

<script>
import { getUnixTime } from "@/core/services/helper.service";

export default {
  methods: { getUnixTime },
  props: {
    value: {
      type: [String, Number, null],
      required: true,
    },
    timezone: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.date-stat {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.18px;
  line-height: 1;
}
</style>