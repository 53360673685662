var render = function render(){
  var _vm$stats;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$stats = _vm.stats) !== null && _vm$stats !== void 0 && _vm$stats.length ? _c('v-card', {
    attrs: {
      "outlined": _vm.$vuetify.theme.dark
    }
  }, [_c('v-row', [_vm._l(_vm.stats, function (stat, index) {
    return [_c('v-col', {
      key: stat === null || stat === void 0 ? void 0 : stat.title,
      staticClass: "pa-3",
      attrs: {
        "cols": "6",
        "md": "auto",
        "sm": "6"
      }
    }, [_c('div', {
      staticClass: "stat__title ma-4",
      attrs: {
        "gap": "8px"
      }
    }, [_c('svg-icon', {
      attrs: {
        "name": stat === null || stat === void 0 ? void 0 : stat.titleIcon
      }
    }), _c('span', {
      staticClass: "darkGrey--text"
    }, [_vm._v(_vm._s(stat === null || stat === void 0 ? void 0 : stat.title))])], 1), _c('div', {
      staticClass: "stat_value ml-3"
    }, [_c(_vm.getStatComponent(stat === null || stat === void 0 ? void 0 : stat.type), _vm._b({
      tag: "component",
      attrs: {
        "tooltip": stat === null || stat === void 0 ? void 0 : stat.tooltip,
        "value": stat === null || stat === void 0 ? void 0 : stat.value
      }
    }, 'component', stat === null || stat === void 0 ? void 0 : stat.props, false))], 1)]), !_vm.$vuetify.breakpoint.smAndDown && index < _vm.stats.length - 1 ? _c('v-col', {
      key: index,
      staticClass: "pa-2",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      staticClass: "stats-devider",
      attrs: {
        "inset": "",
        "vertical": ""
      }
    })], 1) : _vm._e()];
  })], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }