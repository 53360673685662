var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('div', {
    staticClass: "date-stat darkGrey--text"
  }, [_c('vue-hoverable-date', {
    attrs: {
      "date": _vm._f("unix")(_vm.value),
      "timezone": _vm.timezone
    }
  })], 1) : _c('empty');

}
var staticRenderFns = []

export { render, staticRenderFns }